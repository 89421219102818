var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page",attrs:{"id":"authentication_dashboard"}},[_c('v-row',{staticClass:"mt-16 mt-md-0",staticStyle:{"width":"100%","max-width":"1500px","margin":"0 auto"},attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6","order":_vm.$vuetify.breakpoint.mdAndDown ? 'last' : 'first'}},[_c('div',{staticClass:"decoration-img-wrapper elevation-1 rounded-xl"},[_c('img',{ref:"img",staticClass:"decoration-img",attrs:{"alt":"Decoration image","src":_vm.active_img}})]),_c('h3',{staticClass:"\n          base-body-text\n          text-center\n          title\n          mt-5 mt-md-8\n          mb-5\n          mx-5 mx-md-0\n        "},[_vm._v(" "+_vm._s(_vm.$t("onboarding-decoration.heading-ai"))+" ")]),_c('p',{staticClass:"base-body-text description text-center"},[_vm._v(" "+_vm._s(_vm.$t("onboarding-decoration.welcome"))+" ")]),_c('div',{staticClass:"d-flex align-center justify-center mt-2"},[_c('v-btn',{staticClass:"navigate-icon",class:{
            'rotate-180': _vm.get_site_direction === _vm.SiteDirections.RTL
          },attrs:{"icon":""},on:{"click":function($event){return _vm.change_picture(0)}}},[_c('img',{attrs:{"alt":"Navigate to left picture icon","src":require("@/assets/icons/direction-left.svg")}})]),_c('div',{staticClass:"d-flex align-center dots mx-10"},[_c('v-btn',{staticClass:"dot",attrs:{"icon":""},on:{"click":function($event){return _vm.update_dot(0)}}},[_c('img',{staticClass:"rounded",class:{ 'active-dot': _vm.index === 0 },attrs:{"alt":"ellipse icon for navigate picture","src":require("@/assets/icons/ellipse.svg")}})]),_c('v-btn',{staticClass:"dot",attrs:{"icon":""},on:{"click":function($event){return _vm.update_dot(1)}}},[_c('img',{staticClass:"rounded",class:{ 'active-dot': _vm.index === 1 },attrs:{"alt":"ellipse icon for navigate picture","src":require("@/assets/icons/ellipse.svg")}})]),_c('v-btn',{staticClass:"dot",attrs:{"icon":""},on:{"click":function($event){return _vm.update_dot(2)}}},[_c('img',{staticClass:"rounded",class:{ 'active-dot': _vm.index === 2 },attrs:{"alt":"ellipse icon for navigate picture","src":require("@/assets/icons/ellipse.svg")}})])],1),_c('v-btn',{staticClass:"navigate-icon",class:{
            'rotate-180': _vm.get_site_direction === _vm.SiteDirections.RTL
          },attrs:{"icon":""},on:{"click":function($event){return _vm.change_picture(1)}}},[_c('img',{attrs:{"alt":"Navigate to right picture icon","src":require("@/assets/icons/direction-right.svg")}})])],1)]),_c('v-col',{attrs:{"cols":"12","md":"6","order":_vm.$vuetify.breakpoint.mdAndDown ? 'first' : 'last'}},[_c('div',{staticClass:"d-flex align-center justify-center flex-column",staticStyle:{"width":"100%"}},[_c('img',{staticClass:"logo-tech mt-8 mt-md-0",attrs:{"src":require("@/assets/logos/qudraTechBlack.svg")}}),_c('div',{staticClass:"mt-6 mt-md-12 d-flex align-center justify-center flex-wrap",staticStyle:{"width":"100%","gap":"12px"}},[_c('v-btn',{staticClass:"base-cancel-button btn",attrs:{"elevation":"0","outlined":""},on:{"click":function($event){return _vm.$router.push({
                path: '/auth/login'
              })}}},[_vm._v(" "+_vm._s(_vm.$t("auth.login-title"))+" ")]),_c('v-btn',{staticClass:"base-submit-button btn btn-signup px-12",attrs:{"elevation":"0","text":""},on:{"click":function($event){return _vm.$router.push({
                path: '/auth/signup'
              })}}},[_vm._v(" "+_vm._s(_vm.$t("shared.get-started"))+" ")])],1)])])],1),_c('div',{staticClass:"lang-box"},[_c('ChangeLanguage',{attrs:{"extra_padding_class":true}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }